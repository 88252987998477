<div class="row mb-3 justify-content-centerx">
  <label for="mainInscl" class="col-xl-6 col-form-label text-xl-end">สิทธิที่ใช้เบิก</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="mainInscl" formDisabled
           [ngModel]="fund.mainInsclDesc || '-'">
  </div>
</div>
<div class="row mb-3 justify-content-centerx">
  <label for="subInscl" class="col-xl-6 col-form-label text-xl-end">ประเภทสิทธิย่อย</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="subInscl" formDisabled
           [ngModel]="fund.subInsclDesc || '-'">
  </div>
</div>
<div class="row mb-3 justify-content-centerx">
  <label for="cardid" class="col-xl-6 col-form-label text-xl-end">รหัสบัตรประกันสุขภาพ</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="cardid" formDisabled [ngModel]="fund?.cardId || '-'">
  </div>
</div>

<div class="row mb-3 justify-content-centerx">
  <label for="transDate" class="col-xl-6 col-form-label text-xl-end">วันที่เริ่มใช้หน่วยบริการ</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="transDate"
           formDisabled [ngModel]="(fund?.startDate | thaidatetime:{month:'long'}) || '-'">
  </div>
</div>

<div class="row mb-3 justify-content-centerx">
  <label for="subInsclExpireDate" class="col-xl-6 col-form-label text-xl-end">วันที่หมดสิทธิย่อย</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="subInsclExpireDate"
           formDisabled [ngModel]="(fund?.expireDate | thaidatetime: {month:'long'}) || '-'">
  </div>
</div>

<div class="row mb-3 justify-content-centerx">
  <label for="ucProvince" class="col-xl-6 col-form-label text-xl-end">จังหวัดที่ลงทะเบียนรักษา</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="ucProvince"
           formDisabled [ngModel]="fund?.provinceName || '-'">
  </div>
</div>

<div class="row mb-3 justify-content-centerx">
  <label for="hsub" class="col-xl-6 col-form-label text-xl-end">หน่วยบริการปฐมภูมิ</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="hsub"
           formDisabled [ngModel]="fund?.hospSubName && fund?.hospSub ? fund.hospSubName +' ('+ fund.hospSub+')': '-'">
  </div>
</div>

<div class="row mb-3 justify-content-centerx">
  <label for="hmain" class="col-xl-6 col-form-label text-xl-end">หน่วยบริการที่รับการส่งต่อ</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="hmain"
           formDisabled [ngModel]="fund?.hospMainName && fund?.hospMain? fund.hospMainName+' ('+fund.hospMain+')': '-'">
  </div>
</div>

<div class="row mb-3 justify-content-centerx">
  <label for="model" class="col-xl-6 col-form-label text-xl-end">Model</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="model"
           formDisabled [ngModel]="fund?.paidModel || '-'">
  </div>
</div>

<div class="row mb-3 justify-content-centerx">
  <label for="count" class="col-xl-6 col-form-label text-xl-end">จำนวนครั้งที่เปลี่ยนหน่วยบริการประจำ</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="count"
           formDisabled [ngModel]="fund?.countSelect || '0'">
  </div>
</div>

<div class="row mb-3 justify-content-centerx">
  <label for="hmainOp" class="col-xl-6 col-form-label text-xl-end">หน่วยบริการประจำ</label>
  <div class="col-xl-5">
    <input type="text" class="form-control" id="hmainOp"
           formDisabled
           [ngModel]="fund?.hospMainOpName && fund?.hospMainOp? fund.hospMainOpName+' ('+fund.hospMainOp+')': '-'">
  </div>
</div>
