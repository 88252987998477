<div class="row mb-3">
  <label for="mainInsclDesc" class="col-xl-6 col-form-label text-xl-end">สิทธิที่ใช้เบิก</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="mainInsclDesc" formDisabled
           [ngModel]="fund.mainInsclDesc || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="cardId" class="col-xl-6 col-form-label text-xl-end">รหัสบัตรประกันสุขภาพ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="cardId" formDisabled [ngModel]="fund?.cardId || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="startDate" class="col-xl-6 col-form-label text-xl-end">วันที่เริ่มใช้หน่วยบริการ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="startDate"
           formDisabled [ngModel]="(fund?.startDate | thaidatetime:{month:'long', showTime:false}) || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="provinceName" class="col-xl-6 col-form-label text-xl-end">จังหวัดที่ลงทะเบียนรักษา</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="provinceName"
           formDisabled [ngModel]="fund?.provinceName || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="hsub" class="col-xl-6 col-form-label text-xl-end">หน่วยบริการปฐมภูมิ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="hsub"
           formDisabled [ngModel]="fund?.hospSubName && fund?.hospSub ? fund.hospSubName +' ('+fund.hospSub +')':'-'">
  </div>
</div>
<div class="row mb-3">
  <label for="hmain" class="col-xl-6 col-form-label text-xl-end">หน่วยบริการที่รับการส่งต่อ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="hmain"
           formDisabled
           [ngModel]="fund?.hospMainName && fund?.hospMain? fund.hospMainName+' ('+fund.hospMain+')' : '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="model" class="col-xl-6 col-form-label text-xl-end">Model</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="model"
           formDisabled [ngModel]="fund?.paidModel || '-'">
  </div>
</div>
<div class="row mb-3">
  <label for="count" class="col-xl-6 col-form-label text-xl-end">จำนวนครั้งที่เปลี่ยนหน่วยบริการประจำ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="count"
           formDisabled [ngModel]="fund?.countSelect || '0'">
  </div>
</div>
<div class="row mb-3">
  <label for="hmainOp" class="col-xl-6 col-form-label text-xl-end">หน่วยบริการประจำ</label>
  <div class="col-xl-4">
    <input type="text" class="form-control" id="hmainOp"
           formDisabled
           [ngModel]="fund?.hospMainOpName && fund?.hospMainOp ? fund.hospMainOpName +' ('+fund.hospMainOp+')': '-'">
  </div>
</div>
